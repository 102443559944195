import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from 'theme/GlobalStyle';
import theme from '../../../../theme';

const BetGlobalProvider = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      {children}
    </>
  </ThemeProvider>
);

BetGlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BetGlobalProvider;