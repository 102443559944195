import styled, { css } from 'styled-components';
import breakpointsMedia from 'theme/utils/breakpointsMedia';

const arrowDown =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADmSURBVHgB7ZKxDsFQFIbPufQ9+igkDCajsCAsNp5APYF4gKajYLFJdBBv4BH6CF1p2qOaKJL23vYaGM43/+f/v+EAMAzDMD8GVQF7exwIFP0oovOo07SgAPbOnQrAdhjAatxr7GVZASpDFEsiqCHi3N64lir/yCBhciOq4KjySgEguLxk5BLJeJx5u/XhWwEMg2Fc5KkkMsa9axTUQdUPBXDWB5OEcYrTZtpPtHj+RN74pNvyVN2FBGQSSYnmeCmBPIkPSo6XFpBKaIxrCWRKaI5rC6QSFcMhAP8WBjOdcYZhGOYvuAM9FILrRk2fCgAAAABJRU5ErkJggg==';

export const FooterWrapper = styled.footer`
  ${({ theme, customStyles }) => css`
    margin-top: ${theme.spacings.xl};
    padding: ${theme.spacings.xl} 0 0;
    background-color: ${theme.colors.base['900']};

    ${breakpointsMedia({
      lg: css`
        margin-top: ${theme.spacings.xxxl};
        padding: ${theme.spacings.xxxl} 0 0;
      `,
    })}

    ${customStyles}
  `}
`;

export const ListGroup = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.md};

    ${breakpointsMedia({
      lg: css`
        margin-bottom: ${theme.spacings.xxl};
      `,
    })}

    &.visible {
      ul {
        display: flex;
      }

      h5 {
        &::after {
          transform: rotate(180deg);
        }
      }
    }

    h5 {
      display: flex;
      justify-content: space-between;
      padding: ${theme.spacings.sm} 0;
      margin-bottom: ${theme.spacings.md};
      border-bottom: 0.1rem solid ${theme.colors.base['950']};

      ${breakpointsMedia({
        lg: css`
          border: 0;
          padding: 0;
        `,
      })};

      &::after {
        content: '';
        width: 1.6rem;
        height: 1.6rem;
        display: block;
        background-size: 1.6rem 1.6rem;
        background-image: url(${arrowDown});

        ${breakpointsMedia({
          lg: css`
            display: none;
          `,
        })};
      }
    }

    ul {
      display: none;
      flex-direction: column;
      gap: ${theme.spacings.md};

      ${breakpointsMedia({
        lg: css`
          display: flex;
        `,
      })}
    }
  `}
`;

export const AppDownload = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: ${theme.spacings.xxl};
    padding: ${theme.spacings.xl};
    border-radius: ${theme.borderRadius};
    border: 0.5px solid ${theme.colors.base['950']};

    ${breakpointsMedia({
      lg: css`
        margin-top: 0;
        width: max-content;
        align-items: center;
        justify-content: flex-start;
        padding: ${theme.spacings.md};
      `,
    })}

    img {
      &:first-of-type {
        margin-right: ${theme.spacings.sm};
      }
    }

    h6 {
      width: 100%;
      text-align: center;
      margin-bottom: ${theme.spacings.sm};

      ${breakpointsMedia({
        lg: css`
          width: auto;
          text-align: left;
          margin-bottom: 0;
          margin-right: ${theme.spacings.md};
        `,
      })};
    }
  `}
`;

export const SocialMediaIcons = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    gap: ${theme.spacings.md};
    margin-top: ${theme.spacings.xl};
    margin-bottom: ${theme.spacings.xxxl};

    ${breakpointsMedia({
      lg: css`
        margin: 0;
        justify-content: flex-end;
      `,
    })}

    svg {
      width: 2.4rem;
      height: 2.4rem;
      max-width: 100%;
      max-height: 100%;
    }
  `}
`;

export const GameArenaLogo = styled.div`
  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;

    ${breakpointsMedia({
      lg: css`
        margin-right: 0;
      `,
    })}
  }
`;

export const Copyright = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.xl} 0;
    margin-top: ${theme.spacings.xl};
    background-color: ${theme.colors.base['950']};

    p {
      ${breakpointsMedia({
        lg: css`
          text-align: left;
        `,
      })}
    }
  `}
`;
