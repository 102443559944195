import styled, { css } from 'styled-components';
import breakpointsMedia from 'theme/utils/breakpointsMedia';
import { TextStyleVariants } from 'components/foundation/Text';

const searchIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAABCcAAAQnAEmzTo0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIOSURBVHgBzViBcYMwDBRMwAjeoOkESTfoBqUTlA2STtB0AugESSdIOkHTCcIGYQNVPtwLMbJRwIT+nc5nsJS3eMt2IugJRFTUJMY0yiiKSrgViEBClpHtyE7I42Tep4bwKEQUWe4h4UMelBgFe+lJxM5aBkOA9efJMSzeJL8dOQh9UzNz+FRkW7IfstL0wYyfky3gLHQbB7IHEn8FUuiZoDv1K509QQwt6qMjTg5SYK0ZDhsJESuWMn4cujVlAnACXsEAmKxy2VZdjjmXGQgAR6Zyn4NiHI4YqIZgvWpPTJZaMohNu2DivIbaCsyqerceazIp64B1ub9gD4HhyNLONdgeuIURQHGLronHeN61m9jDODhY/cTWqdaQEjiGQsk8u0hGDLcFt2W0CHUOuiVchBSMA27DLpud2NQam9QdjIO51a/YWsfVIbxyM5VAUof+RP1pPddkhp3y2mRSaGvzwzVYvNf0JKOQPx8pn1PBOEyz2zdmwZ2HljAA2p+J2X0eMs4Z8tjglUcRrGXQ/8TYCFQ4ghyxFqaEyBL91yfn54q4gNTo5ei7dezJvuC852kfBXX9egRZpS+oBj2DFERsjeNDfgMxpDIMdHNFtxSuJqU8wbqI5NhYDMFINYilKPv3Q2ckccTxkoqgJ8zMlelqoVfSS4EmRc0T8+oepoIjUzOYEni5ktfwH6Cz0szML37qmEXdcj2uAAAAAElFTkSuQmCC';

const inputSearchIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAABCcAAAQnAEmzTo0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAG+SURBVHgBpZPRLgNBFIbPOSUkbvYR9taF0ssSiQ2ueQM8AZ5A+wY8QesJcI2MRKSXbYm4tJ7AhkgEe44zs9u11W4r8SeTnZmd+eafc84gDJFpP3r89rINiPPJjDwRYidYLJ9CgbAP0HrwRT4bIrBSsD7UVl9dKjcLQebmfoEhPtGun05F2pwDFPAFc3CEw9XF8v4AyDph/jQpJNJNO2u/rmFabT+OqYaIW3ZMhLWgOlfvA13e3Db0ozGRkJCDoFoJoUAX191DJNy1fSYM1qtzVw5s3TiIlWB9FMRqbXl+D0E6bjPDZm+eOP5IBupmWBCHSZNxnPa2MpBezk9/duGPohL34ufZ2Lk5RPCcIcJn+IdInUQOJFnax+trIlvbiykxk7Npi9CYR+8vHKYkNpIG3YHWl136nCueem2Mg+SzjIBHGQgSO0lhCWya1t1BIcRWf1K4A1nOnki+0CB9UzRZ6kD8oW4nfBbZ0IP2ctyQaFKLdzbsA1mdX3drWvqFjnpOEvfo52E4YP/nTW3oMAu+lsmVsJzRDDfhHTwWMnkYjjrctNveu26anoYoqFSi3wdmMILmSNA4ORiXDoji+jdS/sZ706AeSAAAAABJRU5ErkJggg==';

const menuModifiers = {
  showSidebar: () => css`
    opacity: 1;
    pointer-events: auto;
  `,
  toggleMenuIcon: () => css`
    span,
    &::before,
    &::after {
      width: 2.2rem;
    }

    span {
      opacity: 0;
    }

    &::before {
      top: 2.3rem;
      transform: rotate(45deg);
    }

    &::after {
      bottom: 2.2rem;
      transform: rotate(-45deg);
    }
  `,
  hasSubMenu: () => css`
    top: 9.6rem;
  `,
  isSearchVisible: () => css`
    opacity: 1;
    pointer-events: auto;
  `,
};

export const MenuWrapper = styled.header`
  ${({ theme }) => css`
    top: 0;
    z-index: 10;
    position: sticky;
    background-color: ${theme.colors.base['900']};
  `}
`;

export const TopBar = styled.div`
  height: 4.8rem;

  .mobile-icons {
    display: flex;
    height: 4.8rem;

    ${breakpointsMedia({
      lg: css`
        display: none;
      `,
    })}
  }
`;

export const TopBarContent = styled.div`
  height: 100%;
`;

export const TopBarCategories = styled.ul`
  ${({ theme, $isSidebarOpen, $hasSubMenu }) => css`
    left: 0;
    opacity: 0;
    width: 100%;
    top: 4.8rem;
    display: flex;
    position: fixed;
    pointer-events: none;
    flex-direction: column;
    transition:
      ${theme.transition},
      opacity 0.2s;

    ${!!$hasSubMenu && menuModifiers.hasSubMenu()}

    ${breakpointsMedia({
      lg: css`
        top: auto;
        opacity: 1;
        width: auto;
        right: auto;
        height: 100%;
        position: relative;
        flex-direction: row;
        pointer-events: auto;
      `,
    })}

    ${$isSidebarOpen && menuModifiers.showSidebar()}
  `}
`;

export const CategoryItem = styled.li`
  ${({ theme, $categoryColor }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: ${$categoryColor || theme.colors.base['900']};

    ${breakpointsMedia({
      lg: css`
        width: auto;
      `,
    })}

    &:first-of-type {
      background-color: ${theme.colors.base['00']};

      span {
        opacity: 1;
        color: ${theme.colors.base['900']};
      }
    }

    a {
      width: 100%;
      height: 100%;
      display: block;
      align-content: center;
      text-decoration: none;
      text-transform: uppercase;
      color: ${theme.colors.base['00']};
      padding: ${theme.spacings.md};

      span {
        color: ${theme.colors.base['00']};
        opacity: 0.7;
      }

      ${breakpointsMedia({
        lg: css`
          text-align: center;
          padding: 0 ${theme.spacings.lg};
        `,
      })}
    }
  `}
`;

export const BottomBar = styled.div`
  ${({ theme, $currentCategoryColor }) => css`
    display: flex;
    height: 4.8rem;
    background-color: ${$currentCategoryColor || theme.colors.base['50']};
  `}
`;

export const BottomBarCategories = styled.ul`
  ${({ theme, $currentCategoryColor }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.xl};

    height: 100%;
    overflow-x: auto;

    ${breakpointsMedia({
      lg: css`
        overflow-x: visible;
      `,
    })}

    li {
      a {
        display: flex;
        text-wrap: nowrap;
        align-items: center;
        gap: ${theme.spacings.sm};
        color: ${$currentCategoryColor
          ? theme.colors.base['00']
          : theme.colors.base['900']};
      }
    }
  `}
`;

export const OpenSidebarButton = styled.button`
  ${({ theme, $isSidebarOpen }) => css`
    border: 0;
    width: 4.8rem;
    height: 4.8rem;
    font-size: 0rem;
    cursor: pointer;
    color: transparent;
    position: relative;
    background: transparent;

    ${breakpointsMedia({
      lg: css`
        display: none;
      `,
    })}

    span,
    &::before,
    &::after {
      content: ' ';
      width: 2rem;
      left: 1.5rem;
      display: block;
      height: 0.3rem;
      position: absolute;
      transform: rotate(0);
      transition: ${theme.transition};
      border-radius: ${theme.borderRadius};
      background-color: ${theme.colors.base['00']};
    }

    span {
      opacity: 1;
      top: 2.3rem;
    }

    &::before {
      top: 1.6rem;
    }

    &::after {
      bottom: 1.5rem;
    }

    ${$isSidebarOpen && menuModifiers.toggleMenuIcon()}
  `}
`;

export const SearchButton = styled.div`
  button {
    border: 0;
    height: 100%;
    width: 4.8rem;
    font-size: 0px;
    cursor: pointer;
    color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 2.4rem 2.4rem;
    background-image: url(${searchIcon});
  }
`;

export const SearchContainer = styled.div`
  ${({ theme, $isSearchVisible }) => css`
    opacity: 0;
    width: 100%;
    top: 4.8rem;
    height: 4.8rem;
    position: absolute;
    pointer-events: none;
    background-color: ${theme.colors.base['900']};
    transition:
      all 0.2s ease 0s,
      opacity 0.2s;

    ${!!$isSearchVisible && menuModifiers.isSearchVisible()}

    .search-container__grid {
      height: 100%;

      form {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: ${theme.spacings.md};

        input {
          border: 0;
          width: 100%;
          height: 3.2rem;
          position: relative;
          padding-left: 3.2rem;
          background-color: ${theme.colors.base['00']};
          color: ${theme.colors.base['500']};
          border-radius: ${theme.borderRadius};
          ${TextStyleVariants({
            $variant: { typography: 'bodyTextXXXSmall' },
          })};
          background-image: url(${inputSearchIcon});
          background-size: 1.6rem 1.6rem;
          background-repeat: no-repeat;
          background-position: 0.8rem center;

          ${breakpointsMedia({
            lg: css`
              width: 32rem;
            `,
          })}

          &::placeholder {
            color: ${theme.colors.base['500']};
          }

          &:focus-visible {
            outline: transparent;
          }
        }

        span {
          display: none;

          ${breakpointsMedia({
            lg: css`
              display: inline;
            `,
          })}
        }
      }
    }
  `}
`;
