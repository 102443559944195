import spacings from 'theme/spacings';
import styled, { css } from 'styled-components';
import propToStyle from 'theme/utils/propToStyle';
import breakpointsMedia from 'theme/utils/breakpointsMedia';
import {
  isNumber,
  isObject,
  isBoolean,
  mapValues,
  isUndefined,
} from 'theme/utils/functions';

const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${spacings.xl};
  padding-right: ${spacings.xl};

  ${breakpointsMedia({
    xs: css`
      max-width: initial;
      padding-left: ${spacings.sm};
      padding-right: ${spacings.sm};
      ${propToStyle({ prop: 'cssMaxWidth', css: 'maxWidth' })}
    `,
    sm: css`
      max-width: initial;
      ${propToStyle({ prop: 'cssMaxWidth', css: 'maxWidth' })}
    `,
    md: css`
      max-width: initial;
      padding-left: ${spacings.sm};
      padding-right: ${spacings.sm};
      ${propToStyle({ prop: 'cssMaxWidth', css: 'maxWidth' })}
    `,
    lg: css`
      max-width: 1280px;
      ${propToStyle({ prop: 'cssMaxWidth', css: 'maxWidth' })}
    `,
    xl: css`
      max-width: 1376px;
      ${propToStyle({ prop: 'cssMaxWidth', css: 'maxWidth' })}
    `,
  })}

  ${({ $noGutter }) => {
    const CSSNoGutter = css`
      padding-right: 0;
      padding-left: 0;
    `;

    if (isBoolean($noGutter) && $noGutter) {
      return CSSNoGutter;
    }

    /**
     * To add rules according to the breakpoint,
     * follow the breakpoint rules
     * Example:
     * { xs: false, sm: false, lg: true }
     */
    if (isObject($noGutter)) {
      return breakpointsMedia(
        mapValues($noGutter, ob => (ob ? CSSNoGutter : {}))
      );
    }

    return '';
  }};

  ${propToStyle('overflow')}
  ${propToStyle('$backgroundColor')}
  ${propToStyle({ prop: '$cssHeight', css: 'height' })}
  ${propToStyle({ prop: '$cssPadding', css: 'padding' })}
  ${propToStyle({ prop: '$cssDisplay', css: 'display' })}
  ${propToStyle({ prop: '$cssOverflow', css: 'overflow' })}
  ${propToStyle({ prop: '$cssAlignItems', css: 'alignItems' })}
  ${propToStyle({ prop: '$cssJustifyContent', css: 'justifyContent' })}
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -${spacings.sm};
  margin-right: -${spacings.sm};

  ${({ setMarginGrid }) => {
    if (setMarginGrid) {
      return css`
        margin-right: -${spacings.sm};
        margin-left: -${spacings.sm};
      `;
    }

    return '';
  }}

  ${({ $noGutter }) => {
    const CSSNoGutter = css`
      padding-right: 0;
      padding-left: 0;
    `;

    // If the property value is a boolean
    if (isBoolean($noGutter) && $noGutter) {
      return css`
        ${breakpointsMedia({
          xs: css`
            margin-left: -${spacings.sm};
            margin-right: -${spacings.sm};
          `,
          xl: css`
            margin-left: 0;
            margin-right: 0;
          `,
        })}

        > div {
          padding-right: 0;
          padding-left: 0;
        }
      `;
    }

    /**
     * To add rules according to the breakpoint,
     * follow the breakpoint rules
     * Example:
     * { xs: false, sm: false, lg: true }
     */
    if (isObject($noGutter)) {
      return breakpointsMedia(
        mapValues($noGutter, ob => (ob ? CSSNoGutter : {}))
      );
    }

    return '';
  }}

  ${({ $noMargin }) => {
    const CSSNoMargin = css`
      margin-left: 0;
      margin-right: 0;
    `;

    const CSSMargin = css`
      margin-left: -${spacings.sm};
      margin-right: -${spacings.sm};
    `;

    if (isBoolean($noMargin) && $noMargin) {
      return CSSNoMargin;
    }

    /**
     * To add rules according to the breakpoint,
     * follow the breakpoint rules
     * Example:
     * { xs: false, sm: false, lg: true }
     */
    if (isObject($noMargin)) {
      return breakpointsMedia(
        mapValues($noMargin, ob => (ob ? CSSNoMargin : CSSMargin))
      );
    }

    return '';
  }}

  ${({ gutter }) => {
    if (gutter) {
      return css`
        margin-left: ${gutter};
        margin-right: ${gutter};
      `;
    }

    return '';
  }}

  ${propToStyle('$top')}
  ${propToStyle('$alignItems')}
  ${propToStyle('borderBottom')}
  ${propToStyle('$marginBottom')}
  ${propToStyle('$alignContent')}
  ${propToStyle('$justifyContent')}
  ${propToStyle('$backgroundColor')}
  ${propToStyle({ prop: '$cssWidth', css: 'width' })}
  ${propToStyle({ prop: '$cssHeight', css: 'height' })}
  ${propToStyle({ prop: '$cssMargin', css: 'margin' })}
  ${propToStyle({ prop: '$cssPadding', css: 'padding' })}
  ${propToStyle({ prop: '$cssPosition', css: 'position' })}
  ${propToStyle({ prop: '$cssPaddingBottom', css: 'paddingBottom' })}
`;

const hideColumn = hide =>
  hide
    ? css`
        display: none;
      `
    : '';

const Col = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;

  ${({ $paddingFull }) => {
    if ($paddingFull) {
      return css`
        padding: ${spacings.sm};
      `;
    }

    return css`
      padding-right: ${spacings.sm};
      padding-left: ${spacings.sm};
    `;
  }}

  ${({ $colValue, theme: { grids } }) => {
    if (isNumber($colValue)) {
      return css`
        flex: 0 0 ${(100 * $colValue) / grids.columns}%;
        max-width: ${(100 * $colValue) / grids.columns}%;
      `;
    }
    return breakpointsMedia({
      ...($colValue.xs && {
        xs: css`
          ${hideColumn($colValue.xs === 'hide')}
          flex: 0 0 ${(100 * $colValue.xs) / grids.columns}%;
          max-width: ${(100 * $colValue.xs) / grids.columns}%;
        `,
      }),
      ...($colValue.sm && {
        sm: css`
          ${hideColumn($colValue.sm === 'hide')}
          flex: 0 0 ${(100 * $colValue.sm) / grids.columns}%;
          max-width: ${(100 * $colValue.sm) / grids.columns}%;
        `,
      }),
      ...($colValue.md && {
        md: css`
          ${hideColumn($colValue.md === 'hide')}
          flex: 0 0 ${(100 * $colValue.md) / grids.columns}%;
          max-width: ${(100 * $colValue.md) / grids.columns}%;
        `,
      }),
      ...($colValue.lg && {
        lg: css`
          ${hideColumn($colValue.lg === 'hide')}
          flex: 0 0 ${(100 * $colValue.lg) / grids.columns}%;
          max-width: ${(100 * $colValue.lg) / grids.columns}%;
        `,
      }),
      ...($colValue.xl && {
        xl: css`
          ${hideColumn($colValue.xl === 'hide')}
          flex: 0 0 ${(100 * $colValue.xl) / grids.columns}%;
          max-width: ${(100 * $colValue.xl) / grids.columns}%;
        `,
      }),
    });
  }}

  ${({ $offsetValue, theme: { grids } }) => {
    const CSSOffsetValue = offset => css`
      margin-left: ${(100 * offset) / grids.columns}%;
    `;

    if (isNumber($offsetValue)) {
      return CSSOffsetValue($offsetValue);
    }

    /**
     * To add rules according to the breakpoint,
     * follow the breakpoint rules
     * Example:
     * { xs: false, sm: false, lg: true }
     */
    if (isObject($offsetValue)) {
      return breakpointsMedia(
        mapValues($offsetValue, ob => CSSOffsetValue(ob))
      );
    }

    return '';
  }}

  ${({ $offsetTopValue, theme: { grids } }) => {
    const CSSOffsetTopValue = offset => css`
      margin-top: ${(100 * offset) / grids.columns}%;
    `;

    if (isNumber($offsetTopValue)) {
      return CSSOffsetTopValue($offsetTopValue);
    }

    /**
     * To add rules according to the breakpoint,
     * follow the breakpoint rules
     * Example:
     * { xs: false, sm: false, lg: true }
     */
    if (isObject($offsetTopValue)) {
      return breakpointsMedia(
        mapValues($offsetTopValue, ob => CSSOffsetTopValue(ob))
      );
    }

    return '';
  }}

  ${({ $noGutter }) => {
    const CSSNoGutter = css`
      padding-right: 0;
      padding-left: 0;
    `;

    const CSSGutter = css`
      padding-right: ${spacings.sm};
      padding-left: ${spacings.sm};
    `;

    // If the property value is a boolean
    if (isBoolean($noGutter) && $noGutter) {
      return CSSNoGutter;
    }

    /**
     * To add rules according to the breakpoint,
     * follow the breakpoint rules
     * Example:
     * { xs: false, sm: false, lg: true }
     */
    if (isObject($noGutter)) {
      return breakpointsMedia(
        mapValues($noGutter, ob => (ob ? CSSNoGutter : CSSGutter))
      );
    }

    return '';
  }}

  ${({ $gutterLeft }) => {
    if (isNumber($gutterLeft)) {
      return css`
        padding-left: ${$gutterLeft}px;
      `;
    }
    return '';
  }}

  ${({ $gutterRight }) => {
    if (isNumber($gutterRight)) {
      return css`
        padding-right: ${$gutterRight}px;
      `;
    }
    return '';
  }}

  ${({ $orderValue }) => {
    const CSSOrderValue = order => css`
      order: ${order};
    `;

    if (isUndefined($orderValue)) {
      return '';
    }

    if (isNumber($orderValue)) {
      return CSSOrderValue($orderValue);
    }

    /**
     * To add rules according to the breakpoint,
     * follow the breakpoint rules
     * Example:
     * { xs: false, sm: false, lg: true }
     */
    if (isObject($orderValue)) {
      return breakpointsMedia(mapValues($orderValue, ob => CSSOrderValue(ob)));
    }

    return '';
  }}

  ${({ $hiddenValue }) => {
    const CSSHiddenValue = hidden => css`
      display: ${hidden};
    `;

    if (isBoolean($hiddenValue)) {
      return CSSHiddenValue($hiddenValue);
    }

    /**
     * To add rules according to the breakpoint,
     * follow the breakpoint rules
     * Example:
     * { xs: false, sm: false, lg: true }
     */
    if (isObject($hiddenValue)) {
      return breakpointsMedia(
        mapValues($hiddenValue, ob => (ob ? CSSHiddenValue(ob) : {}))
      );
    }

    return '';
  }}

  ${({ $centerContent }) =>
    $centerContent
      ? css`
          display: flex;
          justify-content: center;
        `
      : ''}

  ${propToStyle('$top')}
  ${propToStyle('$border')}
  ${propToStyle('$minWidth')}
  ${propToStyle('$overflow')}
  ${propToStyle('$flexWrap')}
  ${propToStyle('$position')}
  ${propToStyle('$maxWidth')}
  ${propToStyle('$marginTop')}
  ${propToStyle('$marginTop')}
  ${propToStyle('$textAlign')}
  ${propToStyle('$alignContent')}
  ${propToStyle('$paddingRight')}
  ${propToStyle('$marginBottom')}
  ${propToStyle('$backgroundColor')}
  ${propToStyle({ prop: '$cssWidth', css: 'width' })}
  ${propToStyle({ prop: '$cssMargin', css: 'margin' })}
  ${propToStyle({ prop: '$cssHeight', css: 'height' })}
  ${propToStyle({ prop: '$cssPadding', css: 'padding' })}
  ${propToStyle({ prop: '$cssDisplay', css: 'display' })}
  ${propToStyle({ prop: '$cssMinWidth', css: 'minWidth' })}
  ${propToStyle({ prop: '$cssPaddingTop', css: 'paddingTop' })}
  ${propToStyle({ prop: '$cssAlignItems', css: 'alignItems' })}
  ${propToStyle({ prop: '$cssFlexDirection', css: 'flexDirection' })}
  ${propToStyle({ prop: '$cssPaddingBottom', css: 'paddingBottom' })}
  ${propToStyle({ prop: '$cssJustifyContent', css: 'justifyContent' })}
`;

const Grid = {
  Container,
  Row,
  Col,
};

export default Grid;
