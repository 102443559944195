import React from 'react';

export const TwitterIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
  >
    <path
      fill='#8695AB'
      d='M13.905 10.47 21.35 2h-1.764L13.12 9.352 7.956 2H2l7.809 11.12L2 22h1.764l6.827-7.766L16.044 22H22M4.4 3.302h2.71l12.476 17.46h-2.71'
    />
  </svg>
);

export const YoutubeIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
  >
    <path
      fill='#8695AB'
      d='M21.551 6.96c-.19-1.056-1.102-1.824-2.16-2.064-1.582-.336-4.51-.576-7.68-.576-3.167 0-6.142.24-7.727.576-1.055.24-1.968.96-2.16 2.064-.193 1.2-.384 2.88-.384 5.04s.191 3.84.431 5.04c.193 1.056 1.105 1.824 2.16 2.064 1.68.336 4.56.576 7.73.576 3.168 0 6.048-.24 7.728-.576 1.055-.24 1.967-.96 2.16-2.064.191-1.2.431-2.929.48-5.04-.098-2.16-.338-3.84-.578-5.04Zm-12.43 8.4V8.64L14.975 12 9.12 15.36Z'
    />
  </svg>
);

export const InstagramIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
  >
    <path
      fill='#8695AB'
      fillRule='evenodd'
      d='M7.87 2.123c-1.628.073-3.04.471-4.179 1.606-1.143 1.14-1.536 2.557-1.61 4.168-.045 1.005-.313 8.601.463 10.593a5.04 5.04 0 0 0 2.91 2.903c.634.246 1.356.412 2.416.461 8.86.401 12.145.183 13.53-3.364.246-.63.415-1.353.462-2.41.405-8.883-.066-10.809-1.61-12.351-1.225-1.222-2.666-2.054-12.382-1.606Zm.081 17.945c-.97-.044-1.496-.206-1.848-.342a3.255 3.255 0 0 1-1.888-1.883c-.591-1.513-.395-8.702-.342-9.866.051-1.14.282-2.18 1.086-2.985C5.954 4 7.24 3.513 15.993 3.908c1.142.052 2.186.282 2.992 1.084.995.993 1.489 2.288 1.087 11.008-.044.968-.206 1.493-.342 1.843-.901 2.308-2.973 2.628-11.779 2.225ZM16.09 6.69a1.194 1.194 0 0 0 2.39 0c-.001-.657-.537-1.191-1.196-1.191-.66 0-1.194.534-1.194 1.19Zm-9.227 5.298a5.103 5.103 0 0 0 5.11 5.097 5.103 5.103 0 0 0 5.109-5.097 5.102 5.102 0 0 0-5.11-5.096 5.102 5.102 0 0 0-5.11 5.096Zm1.793 0a3.313 3.313 0 0 1 3.316-3.308 3.313 3.313 0 0 1 3.317 3.308 3.313 3.313 0 0 1-3.317 3.31 3.313 3.313 0 0 1-3.316-3.31Z'
      clipRule='evenodd'
    />
  </svg>
);

export const FacebookIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
  >
    <path
      fill='#8695AB'
      d='M22.489 11.99c0-5.792-4.697-10.488-10.489-10.488-5.792 0-10.489 4.696-10.489 10.489 0 5.235 3.835 9.574 8.85 10.362v-7.33H7.697v-3.032h2.664V9.68c0-2.629 1.567-4.082 3.962-4.082 1.148 0 2.348.205 2.348.205v2.581h-1.323c-1.302 0-1.71.809-1.71 1.64v1.967h2.91l-.465 3.033h-2.444v7.329c5.015-.786 8.85-5.126 8.85-10.362Z'
    />
  </svg>
);

export const TiktokIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
  >
    <path
      fill='#8695AB'
      d='M18.345 6.42a4.448 4.448 0 0 1-.384-.223 5.4 5.4 0 0 1-.986-.837 4.65 4.65 0 0 1-1.111-2.293h.004c-.085-.496-.05-.817-.044-.817h-3.35V15.2c0 .174 0 .346-.007.516l-.003.063c0 .01 0 .02-.002.029v.007a2.844 2.844 0 0 1-1.43 2.257c-.423.24-.9.367-1.386.366a2.834 2.834 0 0 1-2.825-2.844 2.834 2.834 0 0 1 2.825-2.843c.295 0 .589.046.87.137l.004-3.41a6.22 6.22 0 0 0-4.794 1.403 6.573 6.573 0 0 0-1.434 1.768c-.141.244-.675 1.223-.74 2.813-.04.902.231 1.837.36 2.223v.008c.081.228.396 1.004.91 1.659.413.524.902.986 1.45 1.368v-.008l.008.008C7.901 21.822 9.7 21.75 9.7 21.75c.311-.013 1.354 0 2.537-.561 1.313-.622 2.06-1.549 2.06-1.549a6.44 6.44 0 0 0 1.123-1.866 6.991 6.991 0 0 0 .405-2.134V8.77c.04.024.581.382.581.382s.78.5 1.996.825c.873.232 2.048.28 2.048.28V6.934c-.412.045-1.248-.085-2.104-.512Z'
    />
  </svg>
);

export const KwaiIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
  >
    <path
      stroke='#8695AB'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M10.425 21.25h6.739a2.695 2.695 0 0 0 2.695-2.695v-3.883a2.696 2.696 0 0 0-2.695-2.695h-6.739a2.696 2.696 0 0 0-2.696 2.695v3.883a2.696 2.696 0 0 0 2.696 2.695ZM9.164 10.51a3.88 3.88 0 1 0 0-7.76 3.88 3.88 0 0 0 0 7.76Z'
    />
    <path
      stroke='#8695AB'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M16.452 10.51a3.407 3.407 0 1 0 0-6.814 3.407 3.407 0 0 0 0 6.815ZM7.73 15.297l-2.242-1.294a.898.898 0 0 0-1.347.778v3.676a.899.899 0 0 0 1.347.778l2.242-1.293'
    />
  </svg>
);

export const WhatsappIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
  >
    <path
      fill='#8695AB'
      fillRule='evenodd'
      d='M17.815 16.121c-.241.682-1.2 1.247-1.963 1.412-.523.11-1.205.199-3.503-.754-2.581-1.069-6.165-4.878-6.165-7.413 0-1.29.744-2.793 2.045-2.793.626 0 .764.012.97.507.24.582.829 2.016.899 2.163.289.603-.294.956-.717 1.482-.135.158-.288.329-.117.623.17.288.758 1.246 1.622 2.015 1.116.995 2.02 1.312 2.345 1.447.24.1.529.077.705-.111.223-.241.5-.641.782-1.035.199-.282.452-.318.717-.218.179.062 2.454 1.119 2.55 1.288.07.123.07.705-.17 1.387ZM11.995 2h-.004C6.478 2 1.994 6.485 1.994 12c0 2.187.705 4.215 1.904 5.86l-1.246 3.717 3.843-1.228a9.922 9.922 0 0 0 5.5 1.651c5.514 0 9.999-4.485 9.999-10S17.509 2 11.996 2Z'
      clipRule='evenodd'
    />
  </svg>
);

export const BlueskyIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 576 512'
    width={24}
    height={24}
  >
    <path
      fill='#8695ab'
      d='M407.8 294.7c-3.3-.4-6.7-.8-10-1.3 3.4.4 6.7.9 10 1.3zM288 227.1c-26.1-50.7-97.1-145.2-163.1-191.8C61.6-9.4 37.5-1.7 21.6 5.5 3.3 13.8 0 41.9 0 58.4S9.1 194 15 213.9c19.5 65.7 89.1 87.9 153.2 80.7 3.3-.5 6.6-.9 10-1.4-3.3.5-6.6 1-10 1.4-93.9 14-177.3 48.2-67.9 169.9C220.6 589.1 265.1 437.8 288 361.1c22.9 76.7 49.2 222.5 185.6 103.4 102.4-103.4 28.1-156-65.8-169.9-3.3-.4-6.7-.8-10-1.3 3.4.4 6.7.9 10 1.3 64.1 7.1 133.6-15.1 153.2-80.7C566.9 194 576 75 576 58.4s-3.3-44.7-21.6-52.9c-15.8-7.1-40-14.9-103.2 29.8C385.1 81.9 314.1 176.4 288 227.1z'
    />
  </svg>
);

export const ThreadsIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 448 512'
    width={24}
    height={24}
  >
    <path
      fill='#8695ab'
      d='M331.5 235.7c2.2.9 4.2 1.9 6.3 2.8 29.2 14.1 50.6 35.2 61.8 61.4 15.7 36.5 17.2 95.8-30.3 143.2-36.2 36.2-80.3 52.5-142.6 53h-.3c-70.2-.5-124.1-24.1-160.4-70.2-32.3-41-48.9-98.1-49.5-169.6v-.5c.5-71.5 17.1-128.6 49.4-169.6 36.3-46.1 90.3-69.7 160.5-70.2h.3c70.3.5 124.9 24 162.3 69.9 18.4 22.7 32 50 40.6 81.7l-40.4 10.8c-7.1-25.8-17.8-47.8-32.2-65.4-29.2-35.8-73-54.2-130.5-54.6-57 .5-100.1 18.8-128.2 54.4C72.1 146.1 58.5 194.3 58 256c.5 61.7 14.1 109.9 40.3 143.3 28 35.6 71.2 53.9 128.2 54.4 51.4-.4 85.4-12.6 113.7-40.9 32.3-32.2 31.7-71.8 21.4-95.9-6.1-14.2-17.1-26-31.9-34.9-3.7 26.9-11.8 48.3-24.7 64.8-17.1 21.8-41.4 33.6-72.7 35.3-23.6 1.3-46.3-4.4-63.9-16-20.8-13.8-33-34.8-34.3-59.3-2.5-48.3 35.7-83 95.2-86.4 21.1-1.2 40.9-.3 59.2 2.8-2.4-14.8-7.3-26.6-14.6-35.2-10-11.7-25.6-17.7-46.2-17.8h-.7c-16.6 0-39 4.6-53.3 26.3l-34.4-23.6c19.2-29.1 50.3-45.1 87.8-45.1h.8c62.6.4 99.9 39.5 103.7 107.7l-.2.2zm-156 68.8c1.3 25.1 28.4 36.8 54.6 35.3 25.6-1.4 54.6-11.4 59.5-73.2-13.2-2.9-27.8-4.4-43.4-4.4-4.8 0-9.6.1-14.4.4-42.9 2.4-57.2 23.2-56.2 41.8l-.1.1z'
    />
  </svg>
);

export const TwitchIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'
    width={24}
    height={24}
  >
    <path
      fill='#8695ab'
      d='M391.2 103.5h-38.7v109.7h38.6zM285 103h-38.6v109.8H285zM120.8 0 24.3 91.4v329.2h115.8V512l96.5-91.4h77.3L487.7 256V0zm328.3 237.8-77.2 73.1h-77.3l-67.6 64v-64h-86.9V36.6h309z'
    />
  </svg>
);
