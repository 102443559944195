/* eslint-disable react/display-name */
import React from 'react';

import BetPageWrapper from 'components/wrapper/BetPage';
import BetGlobalProvider from 'components/wrapper/BetPage/provider';

const BetPageHOC =
  (PageComponent, { pageWrapperProps }) =>
  props => (
    <BetGlobalProvider>
      <BetPageWrapper
        {...pageWrapperProps}
        // eslint-disable-next-line react/prop-types
        {...props.pageWrapperProps}
        pageProps={{}}
      >
        <PageComponent {...props} />
      </BetPageWrapper>
    </BetGlobalProvider>
  );

export default BetPageHOC;
