import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import VARIAVEIS_GLOBAIS from 'theme/utils/globalVariables';
import Text from 'components/foundation/Text';

const Logo = ({ width = 180, height = 40, customLogoTag = 'p' }) => (
  <Text
    as={customLogoTag}
    $variant={{ typography: 'hideText' }}
  >
    Game Arena
    <Link
      target='_top'
      prefetch={false}
      href={`${VARIAVEIS_GLOBAIS.HOST_GAMEARENA}/`}
      style={{
        display: 'flex',
      }}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        fill='none'
        viewBox='0 0 180 40'
      >
        <title>gamearena.gg</title>
        <mask
          id='a'
          width={31}
          height={33}
          x={0}
          y={1}
          maskUnits='userSpaceOnUse'
          style={{
            maskType: 'luminance',
          }}
        >
          <path
            fill='#fff'
            d='m25.444 26.02 1.603 2.524c2.017-2.282 3.36-5.141 3.854-8.208l-2.88.177a13.372 13.372 0 0 1-2.576 5.506v.002-.001ZM1.195 22.714C3.89 31.09 12.617 35.64 20.646 32.854c.197-.068.392-.143.584-.218l-1.593-2.51c-6.535 2.192-13.596-1.522-15.787-8.33-2.206-6.85 1.343-14.277 7.91-16.553 6.457-2.239 13.464 1.325 15.79 7.962l2.889-.178c-.04-.137-.078-.272-.12-.41C27.622 4.244 18.896-.307 10.868 2.478 2.838 5.26-1.502 14.339 1.197 22.716l-.002-.002Z'
          />
        </mask>
        <g mask='url(#a)'>
          <path
            fill='url(#b)'
            d='m-1.152 36.175 32.639-.761L30.636.672l-32.637.762.85 34.74v.001Z'
          />
        </g>
        <mask
          id='c'
          width={24}
          height={25}
          x={15}
          y={14}
          maskUnits='userSpaceOnUse'
          style={{
            maskType: 'luminance',
          }}
        >
          <path
            fill='#fff'
            d='M38.777 15.693c.135.3.158.647.05.963l-7.073 20.72a1.214 1.214 0 0 1-.919.81 1.19 1.19 0 0 1-1.138-.431L15.95 20.87a1.307 1.307 0 0 1-.22-1.239c.144-.42.496-.73.918-.809l20.818-3.837c.425-.075.86.088 1.137.43.07.085.127.178.17.276l.004.002Zm-8.094 18.042 5.114-14.987c.185-.546-.266-1.102-.815-1l-15.056 2.774c-.548.102-.78.784-.418 1.228l9.94 12.212a.71.71 0 0 0 1.234-.227h.001Z'
          />
        </mask>
        <g mask='url(#c)'>
          <path
            fill='url(#d)'
            d='m19.027 40.672 23.665-4.58-4.155-21.853-23.665 4.58 4.155 21.853Z'
          />
        </g>
        <path
          fill='#fff'
          d='M50.462 28.928c1.498 0 3.208-.333 4.342-1.96v1.582h3.486v-9.493h-7.229v3.21h3.4c-.107 2.182-1.411 3.096-3.57 3.096-2.994 0-4.535-2.094-4.535-5.326 0-3.231 1.54-5.28 4.106-5.28 2.01 0 3.187.846 3.572 2.54h4.192c-.535-3.677-3.144-6.105-7.764-6.105-5.582 0-8.148 4.099-8.148 8.845 0 4.747 2.566 8.892 8.148 8.892ZM62.852 28.561l.877-3.053h5.433l.877 3.053h4.063L68.519 11.56h-4.148L58.79 28.56h4.063Zm3.594-12.567 1.711 6.06h-3.421l1.71-6.06ZM78.395 28.561V14.835l3.164 13.726h3.722l3.165-13.816V28.56h3.83V11.56h-6.182l-2.673 12.233-2.695-12.233h-6.159V28.56h3.828ZM104.718 28.561v-3.565h-7.313v-3.164h6.608v-3.544h-6.608v-3.163h7.313V11.56H93.576v17.002h11.142ZM112.587 28.561l.877-3.053h5.433l.876 3.053h4.063l-5.582-17.001h-4.148l-5.583 17.001h4.064Zm3.593-12.567 1.711 6.06h-3.421l1.711-6.06h-.001ZM134.718 21.274c1.433-.847 2.331-2.429 2.331-4.59 0-3.698-2.159-5.124-5.154-5.124h-7.7V28.56h3.828v-6.55h3.1l2.375 6.55h4.043l-2.824-7.287h.001Zm-3.358-6.15c1.241 0 1.904.58 1.904 1.694 0 1.115-.663 1.737-1.904 1.737h-3.336v-3.431h3.336ZM149.41 28.561v-3.565h-7.314v-3.164h6.608v-3.544h-6.608v-3.163h7.314V11.56h-11.142v17.002h11.142ZM154.191 28.561V18.177l6.267 10.384h3.892V11.56h-3.828v10.316l-6.266-10.316h-3.893V28.56h3.828ZM168.751 28.561l.877-3.053h5.433l.876 3.053H180l-5.582-17.001h-4.148l-5.582 17.001H168.751Zm3.593-12.567 1.711 6.06h-3.421l1.711-6.06h-.001Z'
        />
        <defs>
          <linearGradient
            id='b'
            x1={-4.905}
            x2={35.034}
            y1={13.983}
            y2={24.496}
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#8F6EAD' />
            <stop
              offset={0.23}
              stopColor='#8F6EAD'
            />
            <stop
              offset={0.25}
              stopColor='#8F6EAD'
            />
            <stop
              offset={0.31}
              stopColor='#8F6EAD'
            />
            <stop
              offset={0.34}
              stopColor='#8F6EAD'
            />
            <stop
              offset={0.35}
              stopColor='#8F6EAD'
            />
            <stop
              offset={0.36}
              stopColor='#8E6FAE'
            />
            <stop
              offset={0.37}
              stopColor='#8D71B0'
            />
            <stop
              offset={0.38}
              stopColor='#8B74B2'
            />
            <stop
              offset={0.39}
              stopColor='#8B78B4'
            />
            <stop
              offset={0.4}
              stopColor='#897BB7'
            />
            <stop
              offset={0.41}
              stopColor='#877FBA'
            />
            <stop
              offset={0.42}
              stopColor='#8781BC'
            />
            <stop
              offset={0.43}
              stopColor='#8786C0'
            />
            <stop
              offset={0.44}
              stopColor='#838AC4'
            />
            <stop
              offset={0.45}
              stopColor='#8091C9'
            />
            <stop
              offset={0.46}
              stopColor='#7996CD'
            />
            <stop
              offset={0.47}
              stopColor='#769BD1'
            />
            <stop
              offset={0.48}
              stopColor='#6CA0D6'
            />
            <stop
              offset={0.49}
              stopColor='#66A4D8'
            />
            <stop
              offset={0.5}
              stopColor='#5EAAD5'
            />
            <stop
              offset={0.51}
              stopColor='#59ADD0'
            />
            <stop
              offset={0.52}
              stopColor='#51B2CE'
            />
            <stop
              offset={0.53}
              stopColor='#4BB6CB'
            />
            <stop
              offset={0.54}
              stopColor='#44BAC6'
            />
            <stop
              offset={0.55}
              stopColor='#46BAC0'
            />
            <stop
              offset={0.56}
              stopColor='#49BABB'
            />
            <stop
              offset={0.57}
              stopColor='#4FBAB5'
            />
            <stop
              offset={0.58}
              stopColor='#50B9B1'
            />
            <stop
              offset={0.59}
              stopColor='#51B9AD'
            />
            <stop
              offset={0.6}
              stopColor='#56B9A8'
            />
            <stop
              offset={0.61}
              stopColor='#5ABAA6'
            />
            <stop
              offset={0.62}
              stopColor='#5EBA9F'
            />
            <stop
              offset={0.63}
              stopColor='#61BA9D'
            />
            <stop
              offset={0.64}
              stopColor='#61BA9A'
            />
            <stop
              offset={0.65}
              stopColor='#62BA97'
            />
            <stop
              offset={0.66}
              stopColor='#62BA97'
            />
            <stop
              offset={0.69}
              stopColor='#62BA97'
            />
            <stop
              offset={0.75}
              stopColor='#62BA97'
            />
            <stop
              offset={0.77}
              stopColor='#62BA97'
            />
            <stop
              offset={1}
              stopColor='#62BA97'
            />
          </linearGradient>
          <linearGradient
            id='d'
            x1={42.822}
            x2={15.548}
            y1={37.539}
            y2={19.085}
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#4F68AE' />
            <stop
              offset={0.47}
              stopColor='#4F68AE'
            />
            <stop
              offset={0.5}
              stopColor='#4F68AE'
            />
            <stop
              offset={0.53}
              stopColor='#4F68AE'
            />
            <stop
              offset={0.54}
              stopColor='#4F69B0'
            />
            <stop
              offset={0.55}
              stopColor='#506DB3'
            />
            <stop
              offset={0.56}
              stopColor='#526FB5'
            />
            <stop
              offset={0.57}
              stopColor='#5275B9'
            />
            <stop
              offset={0.58}
              stopColor='#5379BB'
            />
            <stop
              offset={0.59}
              stopColor='#527DBE'
            />
            <stop
              offset={0.6}
              stopColor='#5081C1'
            />
            <stop
              offset={0.61}
              stopColor='#4D86C6'
            />
            <stop
              offset={0.62}
              stopColor='#4C8BCA'
            />
            <stop
              offset={0.63}
              stopColor='#478FCD'
            />
            <stop
              offset={0.64}
              stopColor='#4196D2'
            />
            <stop
              offset={0.65}
              stopColor='#3D99D5'
            />
            <stop
              offset={0.66}
              stopColor='#369FD9'
            />
            <stop
              offset={0.67}
              stopColor='#35A4DC'
            />
            <stop
              offset={0.68}
              stopColor='#34AAD7'
            />
            <stop
              offset={0.69}
              stopColor='#2EABD6'
            />
            <stop
              offset={0.7}
              stopColor='#28B0D3'
            />
            <stop
              offset={0.71}
              stopColor='#21B5CF'
            />
            <stop
              offset={0.72}
              stopColor='#26B8CD'
            />
            <stop
              offset={0.73}
              stopColor='#2EB7C5'
            />
            <stop
              offset={0.74}
              stopColor='#39B8C2'
            />
            <stop
              offset={0.75}
              stopColor='#3DB8BC'
            />
            <stop
              offset={0.76}
              stopColor='#44B8BA'
            />
            <stop
              offset={0.77}
              stopColor='#49B9B5'
            />
            <stop
              offset={0.78}
              stopColor='#4AB8B1'
            />
            <stop
              offset={0.79}
              stopColor='#4BB8AC'
            />
            <stop
              offset={0.8}
              stopColor='#54B9A9'
            />
            <stop
              offset={0.81}
              stopColor='#58BAA7'
            />
            <stop
              offset={0.82}
              stopColor='#5CBAA1'
            />
            <stop
              offset={0.83}
              stopColor='#5EBA9F'
            />
            <stop
              offset={0.84}
              stopColor='#5FBA9B'
            />
            <stop
              offset={0.85}
              stopColor='#62BA97'
            />
            <stop
              offset={0.86}
              stopColor='#62BA97'
            />
            <stop
              offset={0.88}
              stopColor='#62BA97'
            />
            <stop
              offset={1}
              stopColor='#62BA97'
            />
          </linearGradient>
        </defs>
      </svg>
    </Link>
  </Text>
);

Logo.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  customLogoTag: PropTypes.string,
};

export default React.memo(Logo);
