import styled from 'styled-components';
import propToStyle from 'theme/utils/propToStyle';

const Box = styled.div`
  ${propToStyle('$flexWrap')}
  ${propToStyle('$maxWidth')}
  ${propToStyle('$alignItems')}
  ${propToStyle('$background')}
  ${propToStyle({ prop: '$cssWidth', css: 'width' })}
  ${propToStyle({ prop: '$cssHeight', css: 'height' })}
  ${propToStyle({ prop: '$cssDisplay', css: 'display' })}

  header {
    width: 100%;
  }
`;

export default Box;
