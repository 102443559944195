import React, { useState, useCallback } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import PropTypes from 'prop-types';

import Logo from 'theme/Logo';
import spacings from 'theme/spacings';
import Text from 'components/foundation/Text';
import Grid from 'components/foundation/layout/Grid';
import VARIAVEIS_GLOBAIS from 'theme/utils/globalVariables';
import {
  TwitterIcon,
  InstagramIcon,
  YoutubeIcon,
  FacebookIcon,
  TiktokIcon,
  KwaiIcon,
  TwitchIcon,
  ThreadsIcon,
} from 'components/common/SocialMediaIcons';

import * as S from './styles';

const Footer = ({ data = {}, cssProps }) => {
  const maxColumnLength = 5;
  const menuData = Object.values(data);
  const menuLength = menuData.length;

  const currentYear = new Date().getFullYear();
  const [visibleList, setVisibleList] = useState(null);

  const toggleListVisibility = useCallback(
    index => setVisibleList(visibleList === index ? null : index),
    [visibleList]
  );

  return (
    <S.FooterWrapper
      as='footer'
      role='contentinfo'
      customStyles={cssProps}
    >
      <Grid.Container>
        <Grid.Row>
          <Grid.Col $colValue={{ xs: 12, lg: 9 }}>
            <Grid.Row>
              {menuData?.map((item, index) =>
                index < maxColumnLength ? (
                  <Grid.Col
                    key={`menu-${index}`}
                    $colValue={{
                      xs: 12,
                      lg: index === menuLength - 1 ? 4 : 2,
                    }}
                  >
                    <S.ListGroup
                      className={visibleList === index ? 'visible' : ''}
                    >
                      <Text
                        as='h5'
                        $variant={{ typography: 'bodyTextBold' }}
                        $csscolor='00'
                        onClick={() => toggleListVisibility(index)}
                        aria-expanded={visibleList === index}
                      >
                        {item?.title}
                      </Text>
                      <ul>
                        {item?.submenu?.map((linkItem, indexB) => (
                          <li key={`linkSubmenu-${indexB}`}>
                            <Link
                              target='_top'
                              prefetch={false}
                              href={linkItem?.url}
                            >
                              <Text
                                as='span'
                                $variant={{ typography: 'bodyText' }}
                                $csscolor='500'
                              >
                                {linkItem?.title}
                              </Text>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </S.ListGroup>
                  </Grid.Col>
                ) : (
                  <React.Fragment key={`item-${index}`} />
                )
              )}
            </Grid.Row>

            <Grid.Row>
              <Grid.Col
                $colValue={{ xs: 12, lg: 2 }}
                $cssDisplay='flex'
                $alignContent='center'
                $cssAlignItems='center'
              >
                <Link
                  target='_top'
                  prefetch={false}
                  title='Termos de uso e condições do Game Arena'
                  href={`${VARIAVEIS_GLOBAIS.HOST_GAMEARENA}/termos-de-uso-e-condicoes`}
                >
                  <Text
                    as='span'
                    $csscolor='500'
                    $cssDisplay='block'
                    $variant={{ typography: 'bodyText' }}
                    $cssMargin={{ xs: `${spacings.md} 0`, lg: '0' }}
                  >
                    Termos de uso
                  </Text>
                </Link>
              </Grid.Col>

              <Grid.Col
                $colValue={{ xs: 12, lg: 4 }}
                $cssDisplay='flex'
                $alignContent='center'
                $cssAlignItems='center'
              >
                <Link
                  target='_top'
                  prefetch={false}
                  title='Políticas de privacidade do Game Arena'
                  href={`${VARIAVEIS_GLOBAIS.HOST_GAMEARENA}/politica-de-privacidade`}
                >
                  <Text
                    as='span'
                    $csscolor='500'
                    $variant={{ typography: 'bodyText' }}
                  >
                    Políticas de privacidade
                  </Text>
                </Link>
              </Grid.Col>

              <Grid.Col $colValue={{ xs: 12, lg: 6 }}>
                <S.AppDownload>
                  <Text
                    as='h6'
                    $csscolor='00'
                    $variant={{ typography: 'bodyTextBold' }}
                  >
                    Baixe o App Game Arena
                  </Text>
                  <Link
                    target='_blank'
                    rel='noopener noreferrer'
                    title='Baixar o App Game Arena na App Store'
                    href='https://apps.apple.com/ie/app/game-arena/id6478929837'
                  >
                    <Image
                      width={108}
                      height={36}
                      quality={100}
                      alt='Baixar na App Store'
                      src='/images/app-store-badge.png'
                    />
                  </Link>
                  <Link
                    target='_blank'
                    rel='noopener noreferrer'
                    title='Baixar o App Game Arena na Play Store'
                    href='https://play.google.com/store/apps/details?id=gg.gamearena.app&hl=pt_BR'
                  >
                    <Image
                      width={130}
                      height={36}
                      quality={100}
                      alt='Baixar na Play Store'
                      src='/images/play-store-badge.png'
                    />
                  </Link>
                </S.AppDownload>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col
            $colValue={{ xs: 12, lg: 3 }}
            $cssDisplay='flex'
            $cssFlexDirection='column'
            $cssJustifyContent='space-between'
          >
            <S.SocialMediaIcons>
              <Link
                target='_blank'
                rel='noopener noreferrer'
                title='Facebook Game Arena'
                href='https://www.facebook.com/gamearenagg/'
                aria-label='Acesse o Facebook do Game Arena'
              >
                <FacebookIcon aria-label='Facebook' />
              </Link>
              <Link
                target='_blank'
                title='X Game Arena'
                rel='noopener noreferrer'
                href='https://x.com/gamearenaofc'
                aria-label='Acesse o X do Game Arena'
              >
                <TwitterIcon aria-label='X' />
              </Link>
              <Link
                target='_blank'
                rel='noopener noreferrer'
                title='Tiktok Game Arena'
                aria-label='Acesse o Tiktok do Game Arena'
                href='https://www.tiktok.com/@gamearenaofc'
              >
                <TiktokIcon aria-label='Tiktok' />
              </Link>
              <Link
                target='_blank'
                rel='noopener noreferrer'
                title='Instagram Game Arena'
                aria-label='Acesse o Instagram do Game Arena'
                href='https://www.instagram.com/gamearenaofc/'
              >
                <InstagramIcon aria-label='Instagram' />
              </Link>
              <Link
                target='_blank'
                rel='noopener noreferrer'
                title='Youtube Game Arena'
                aria-label='Acesse o Youtube do Game Arena'
                href='https://www.youtube.com/channel/UCaxhojgGzWPP5XlAA5OafLw'
              >
                <YoutubeIcon aria-label='Youtube' />
              </Link>
              <Link
                target='_blank'
                title='Kwai Game Arena'
                rel='noopener noreferrer'
                aria-label='Acesse o Kwai do Game Arena'
                href='https://www.kwai.com/@gamearenaofc'
              >
                <KwaiIcon aria-label='Kwai' />
              </Link>
              <Link
                target='_blank'
                rel='noopener noreferrer'
                title='Twitch Game Arena'
                href='https://www.twitch.tv/gamearenaofc'
                aria-label='Acesse o Twitch do Game Arena'
              >
                <TwitchIcon aria-label='Twitch' />
              </Link>
              <Link
                target='_blank'
                rel='noopener noreferrer'
                title='Threads Game Arena'
                aria-label='Acesse o Threads do Game Arena'
                href='https://www.threads.net/@gamearenaofc'
              >
                <ThreadsIcon aria-label='Threads' />
              </Link>
            </S.SocialMediaIcons>

            <S.GameArenaLogo>
              <Logo
                width={180}
                height={40}
              />
            </S.GameArenaLogo>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <S.Copyright>
        <Grid.Container>
          <Text
            as='p'
            $csscolor='500'
            $cssTextAlign='center'
            $variant={{ typography: 'bodyTextXXSmall' }}
          >
            Copyright © 2022 - {currentYear} Game Arena. Todos os direitos
            reservados.
          </Text>
        </Grid.Container>
      </S.Copyright>
    </S.FooterWrapper>
  );
};

Footer.propTypes = {
  cssProps: PropTypes.shape({}),
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default React.memo(Footer);
